







































































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsCheckList from '@/components/VsCheckList/Index.vue'
import { isOldSubscription } from '@/utils/rules'
import { UserModule } from '@/store/modules/user'

@Component({
    name: 'VsNextPlanCard',
    components: {
        VsCheckList,
    },
})
export default class extends Vue {
    @Prop({ required: true, default: null }) nextPlan: any
    private loading = false

    get user () {
        return UserModule.user
    }

    get isCustom () {
        return this.nextPlan === 'custom'
    }

    get planSlug () {
        return this.nextPlan?.product?.metadata?.ruleGroup || 'custom'
    }

    get isOldSubscription () {
        return isOldSubscription(this.user.configuration.rules)
    }
}
