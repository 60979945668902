












































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import VsSidebarLayout from '@/components/VsSidebarLayout/Index.vue'
import VsPlanDetails from '../../components/VsPlanDetails/Index.vue'
import { UserModule } from '@/store/modules/user'
import MyAccordion from '@/components/MyAccordion/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsPriceAddableCard from '@/modules/shop/components/VsPriceAddableCard/Index.vue'
import VsNextPlanCard from '../../components/VsNextPlanCard/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsSeparator from '@/components/VsSeparator/Index.vue'
import {
    isFreemium,
    isOldSubscription,
    isRechargeable,
    isRecursive,
    userPlanEmailCreditsRateString,
} from '@/utils/rules'
import { get, orderBy } from 'lodash'
import { AppModule } from '@/store/modules/app'
import {
    valueDurationFormat,
} from '@/utils/products'

import {
    getUserPlan,
    getUserAddons,
    formatPriceName,
    calculateAndFormatItemPrice,
    calculateAndFormatItemPriceAndRate,
} from '@/utils/shop'
import {
    createCart,
    getPricesAddable,
    getShopUser,
    getUserUpcomingInvoice,
    customerPortal,
} from '@/api/shop'
import { PriceWithProductRulesPreset } from '@/api/shop/types/shop'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { me } from '@/api/userapi/users'
import { getUserCredits } from '@/api/consoleApi/user'
import axios from 'axios'

@Component({
    name: 'MyPlan',
    components: {
        VsSidebarLayout,
        VsPlanDetails,
        VsSeparator,
        VsPriceAddableCard,
        MyAccordion,
        VsDropdownButton,
        VsSectionHeader,
        VsLoader,
        VsNextPlanCard,
    },
})
export default class extends Vue {
    private loading = false
    private showDetails = false
    private currentModal = ''
    private userUpcomingInvoice: any = null
    // private priceList: PriceListPopulated | null = null
    private pricesAddable: PriceWithProductRulesPreset[] = []

    async beforeMount () {
        this.loading = true
        await this.getUserAndShopUser()
        await this.getUserCredits()
        await this.getUserUpcomingInvoice()
        await this.getPricesAddable()
        this.loading = false
    }

    get nextPlan () {
        let plan = null
        if (this.userPlan) {
            plan = this.userPlan.price.product.metadata?.ruleGroup
        }
        if (this.isRechargeable || this.isFreemium) {
            plan = 'rechargeable'
        }
        switch (plan) {
            case 'rechargeable':
                return this.getFirstProductsByRuleGroup('communication', 'months')
            case 'small':
            case 'communication':
                return this.getFirstProductsByRuleGroup('acquisition', this.userPlan.price.rate.type)
            case 'business':
            case 'acquisition':
                return this.getFirstProductsByRuleGroup('professional', this.userPlan.price.rate.type)
            case 'professional':
                return 'custom'
            case 'enterprise':
            case 'enterprise-reseller':
            case 'ricaricabile':
            default:
                return null
        }
    }

    get userPlanEmailCreditsRateString () {
        return userPlanEmailCreditsRateString(this.user.configuration.rules)
    }

    get userPlan () {
        return getUserPlan(this.shopUser)
    }

    get shopUserPlanName () {
        return UserModule.shopUserPlanName
    }

    get userPlanEmailCredits () {
        return UserModule.userPlanEmailCredits
    }

    get userPlanEmailCreditsRate () {
        return UserModule.userPlanEmailCreditsRate
    }

    get userAddons () {
        return getUserAddons(this.shopUser)
    }

    private getCreditsValue (creditType: string) {
        if (creditType === 'transactional') creditType = 'senderplus'
        return this.userCredits.find(el => el.key === creditType)?.value || 0
    }

    private formatPriceName (price: any) {
        return formatPriceName(price)
    }

    private calculateAndFormatItemPrice (item: any) {
        return calculateAndFormatItemPrice(item)
    }

    get shopUser () {
        return UserModule.shopUser
    }

    get user () {
        return UserModule.user
    }

    get isOldSubscription () {
        return isOldSubscription(this.user.configuration.rules)
    }

    get userCredits () {
        return UserModule.userCredits
    }

    get userEmailCredits () {
        const emailCredits = this.userCredits.filter(el => el.key === 'email')
        if (emailCredits.length > 0) return emailCredits[0].value
    }

    get isRechargeable () {
        return isRechargeable(this.user.configuration.rules)
    }

    get isRecursive () {
        return isRecursive(this.user.configuration.rules)
    }

    get isFreemium () {
        return isFreemium(this.user.configuration.rules)
    }

    get rechargeStrategyObjectValue () {
        return this.user.configuration.rules.emailRecursivity?.value || 0
    }

    get is4Dem () {
        return get(AppModule.consoleConf, 'is4Dem', false)
    }

    get hasShop () {
        return this.user.configuration.rules.shop
    }

    get siteUrl () {
        return AppModule.siteUrl
    }

    get revisionLink () {
        return this.siteUrl + '/termini-e-condizioni-di-servizio/'
    }

    get addonAddable () {
        return this.pricesAddable.filter(el => !el.metadata?.rateCredits && !el.metadata?.credits)
    }

    get smsAddable () {
        return orderBy(
            this.pricesAddable.filter(el => el.metadata?.credits && el.product.metadata?.creditType === 'sms'),
            'value',
            'asc',
        )
    }

    get emailAddable () {
        return orderBy(
            this.pricesAddable.filter(el => el.metadata?.credits && el.product.metadata?.creditType === 'email'),
            'value',
            'asc',
        )
    }

    get transactionalAddable () {
        return orderBy(
            this.pricesAddable.filter(el => el.metadata?.credits && el.product.metadata?.creditType === 'transactional'),
            'value',
            'asc',
        )
    }

    get isPlanTrialing () {
        return this.shopUser && this.shopUser.subscription && this.shopUser.subscription.paymentStatus === 'trialing'
    }

    private isEmailRecursive (credit: any) {
        return this.isRecursive && credit === 'email'
    }

    private hasUnlimitedEmail (credit: any) {
        return this.user.configuration.rules.emailUnlimited && credit === 'email'
    }

    private parseCreditName (credit: any) {
        switch (credit) {
            case 'email': return 'Email'
            case 'sms': return 'Sms'
            case 'transactional': return this.$t('account.myPlan.transactional')
            default: return credit
        }
    }

    private valueDurationFormat (duration: any) {
        return valueDurationFormat(duration)
    }

    private async getUserCredits () {
        try {
            const resp = await getUserCredits()
            const credits = resp.data
            UserModule.SET_USER_CREDITS(credits)
        } catch (e) {
            console.log(e)
        }
    }

    private rechargeUrl (creditName: string) {
        switch (creditName) {
            case 'email':
                return 'email-5000'
            case 'sms':
                return 'sms-1000'
            case 'transactional':
                return 'senderplus-10000'
        }
    }

    private parseCreditIconName (creditName: string) {
        switch (creditName) {
            case 'email':
                return 'mail'
            case 'sms':
                return 'chat'
            case 'transactional':
                return 'transactional'
        }
    }

    private closePlanModal () {
        this.currentModal = ''
    }

    private openModal (name: string) {
        this.currentModal = name
    }

    private async getUserUpcomingInvoice () {
        try {
            const resp = await getUserUpcomingInvoice()
            this.userUpcomingInvoice = resp.data
        } catch (e) {
            this.userUpcomingInvoice = null
            console.log(e)
        }
    }

    private async getPricesAddable () {
        try {
            const resp = await getPricesAddable()
            this.pricesAddable = resp.data
        } catch (e) {
            this.pricesAddable = []
            console.log(e)
        }
    }

    private calculateAndFormatItemPriceAndRate (item: any) {
        return calculateAndFormatItemPriceAndRate(item)
    }

    private async createCart (priceId: string, sourceName: string) {
        try {
            const resp = await createCart({
                items: [{
                    priceId,
                    quantity: 1,
                }],
            })
            this.$emit('cart-created', resp.data)
            this.trackCartCreatedMixpanelEvent(resp.data, sourceName)
            this.$root.$vsToast({
                heading: 'Prodotto aggiunto al carrello',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })

            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            console.log(e)
        }
    }

    private trackCartCreatedMixpanelEvent (cart: any, sourceName: string) {
        if (UserModule.Mixpanel) {
            UserModule.Mixpanel.track(
                'CartCreated',
                {
                    distinct_id: this.user._id,
                    CartId: cart._id,
                    Trial: false,
                    HasSubscription: !!(this.shopUser && this.shopUser.subscription),
                    Interval: sourceName === 'UserPlanCredits' ? 'una_tantum' : this.userPlan?.price?.rate?.type || 'months',
                    Source: sourceName,
                },
            )
        }
    }

    private async createCartFromUserSubscriptionItems () {
        const items = get(this.shopUser, 'subscription.items', [])
        if (items.length === 0) return
        try {
            const resp = await createCart({
                items,
            })
            this.$emit('cart-created', resp.data)
            this.trackCartCreatedMixpanelEvent(resp.data, 'UserPlanRenewEarly')
            this.$root.$vsToast({
                heading: 'Prodotto aggiunto al carrello',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$router.push({
                name: 'cart',
            })
        } catch (e) {
            console.log(e)
            let heading = 'Qualcosa è andato storto'
            if (axios.isAxiosError(e)) {
                if (e.response?.status === 400 && e.response.data.message === 'CartItemsNotCompatible') {
                    heading = 'Il tuo piano non supporta il rinnovo anticipato. Contatta il supporto per saperne di più'
                }
            }

            this.$root.$vsToast({
                heading,
                aspect: VsToastAspectEnum.alert,
                timeout: 5000,
            })
        }
    }

    private async createCartByCreditType (credit: any) {
        if (!this.hasShop) return
        switch (credit) {
            case 'email': this.emailAddable[0] && this.createCart(this.emailAddable[0]._id, 'UserPlanCredits')
                break
            case 'sms': this.smsAddable[0] && this.createCart(this.smsAddable[0]._id, 'UserPlanCredits')
                break
            case 'transactional': this.transactionalAddable[0] && this.createCart(this.transactionalAddable[0]._id, 'UserPlanCredits')
                break
            default:
        }
    }

    private getFirstProductsByRuleGroup (ruleGroup: string, rateType: string) {
        return orderBy(
            this.pricesAddable.filter(el => el.product.metadata?.ruleGroup === ruleGroup && el.rate.type === rateType),
            'value',
            'asc',
        )[0]
    }

    async getUserAndShopUser () {
        try {
            const resp = await getShopUser()
            UserModule.SET_SHOP_USER(resp.data)
        } catch (e) {
            UserModule.SET_SHOP_USER(null)
        }

        try {
            const resp = await me()
            UserModule.SET_USER(resp.data)
        } catch (e) {
            // TODO: valutare se fare redirect al logout (se non riesce a fare get me è un problema)
            UserModule.SET_USER(null as any)
        }
    }

    async openCustomerPortal () {
        this.loading = true
        try {
            const resp = await customerPortal({
                returnUrl: window.location.href,
            })
            window.location.href = resp.data.url
        } catch (e) {
            this.loading = false
            const statusCode = get(e, 'response.status', '')
            if (statusCode === 403) {
                this.$root.$vsToast({
                    heading: 'Non hai i permessi per completare questa azione',
                    aspect: VsToastAspectEnum.alert,
                    timeout: 3000,
                })
            }
            console.log(e)
        }
    }
}
